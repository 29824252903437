<template>
    <div id="BoneAdd">
        <el-form :model="form" :rules="rules" label-width="120px" ref="form">
            <div class="title">病例信息</div>
            <el-form-item label="病例标题" prop="title">
                <el-input v-model="form.title" maxlength="25" minlength="2" placeholder="请输入病例标题（必填）" />
            </el-form-item>
            <el-form-item label="病例专业" prop="zhuan_ye">
                <el-select v-model="form.zhuan_ye" placeholder="请选择所属专业">
                    <!-- <el-option v-for="(item,index) in list" :key="index" :label="item" :value="index"></el-option> -->
                    <el-option label="关节" value="关节"></el-option>
                    <el-option label="创伤" value="创伤"></el-option>
                    <el-option label="脊柱" value="脊柱"></el-option>
                    <el-option label="运医" value="运医"></el-option>
                    <el-option label="小儿" value="小儿"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="form.gender">
                    <el-radio label="男"></el-radio>
                    <el-radio label="女"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄" prop="age">
                <el-col :span="2"> <el-input type="number" min="1" max="120" v-model="form.age"/></el-col>
                <el-col :span="1" class="sui">岁</el-col>
            </el-form-item>
            <div class="title">病例详情</div>
            <el-form-item label="患者详情主诉" prop="zhu_su">
                <el-input v-model="form.zhu_su" placeholder="请输入主诉（您可以输入10-25个字）" minlength="10" maxlength="25"/>
            </el-form-item>
            <el-form-item label="现病史" prop="xian_bing_shi">
                <el-input v-model="form.xian_bing_shi" placeholder="请输入现病史（您可以输入700个字）" minlength="10" maxlength="700"/>
            </el-form-item>
            <el-form-item label="体格检查/辅助检查">
                <el-input v-model="form.jian_cha" placeholder="请输入体格检查/辅助检查结果，上传相应影像资料（您可以输入700个字）" maxlength="700" />
            </el-form-item>

            <el-form-item label="" class="user-info-list">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :on-remove="
                        param => {
                            return handleRemove(param, 'jc_list');
                        }"
                    :file-list="form.jc_list"
                    :http-request="
                        param => {
                            return beforeAvatarUpload(param, 'jc_list');
                        }
                    "
                >
                    <!--  -->
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="诊断">
                <el-input v-model="form.zhen_duan" placeholder="请输入诊断内容（您可以输入700个字）" maxlength="700" />
            </el-form-item>

            <el-form-item label="患者相关的影像资料补充:" class="user-info-list">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :on-remove="
                        param => {
                            return handleRemove(param, 'zd_list');
                        }"
                    :file-list="form.zd_list"
                    :http-request="
                        param => {
                            return beforeAvatarUpload(param, 'zd_list');
                        }
                    "
                >
                    <!--  -->
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="治疗经过">
                <el-input v-model="form.zljg" placeholder="请描述手术记录与患者术后康复治疗经过等（您可以输入1000个字）" maxlength="1000" />
            </el-form-item>

            <el-form-item label="" class="user-info-list">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :on-remove="
                        param => {
                            return handleRemove(param, 'zljg_list');
                        }"
                    :file-list="form.zljg_list"
                    :http-request="
                        param => {
                            return beforeAvatarUpload(param, 'zljg_list');
                        }
                    "
                >
                    <!--  -->
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>

            <el-form-item label="病例思考">
                <el-input v-model="form.blsk" placeholder="系统性回顾患者收治全过程，对诊疗过程进行总结（您可以输入700个字）" maxlength="700" />
            </el-form-item>

            <el-form-item label="" class="user-info-list">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :on-remove="
                        param => {
                            return handleRemove(param, 'blsk_list');
                        }"
                    :file-list="form.blsk_list"
                    :http-request="
                        param => {
                            return beforeAvatarUpload(param, 'blsk_list');
                        }
                    "
                >
                    <!--  -->
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>

            <el-form-item size="large">
                <el-button  style="background-color: #5680FA;border-color: #5680FA" type="primary" @click="submitFormVideo('form')">确 定</el-button>
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>

        <!-- <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog> -->
    </div>
</template>

<script>
export default {
    name: "BoneAdd",
    data() {
        return {
            gid: sessionStorage.getItem("l-gid") || "",
            pic: "",
            list: ["关节", "创伤", "脊柱", "运医", "小儿", "其他"],
            form: {
                title: "" /* 标题 */,
                gender: "男" /* 性别 */,
                age: "" /* 年龄 */,
                zhuan_ye: "" /* 专业 */,
                zhu_su: "" /* 主诉 */,
                xian_bing_shi: "" /* 现病史 */,
                jian_cha: "" /* 体格检查/辅助检查 */,
                jc_list: [] /* { imgGid: "" } */,
                jc_list_File: [] /* { imgGid: "" } */,

                zhen_duan: "" /* 诊断 */,
                zd_list: [],
                zd_list_File: [] /* { imgGid: "" } */,
                zljg: "" /* 治疗经过 */,
                zljg_list: [],
                zljg_list_File: [] /* { imgGid: "" } */,
                blsk: "" /* 病例思考 */,
                blsk_list: [],
                blsk_list_File: [] /* { imgGid: "" } */
            },
            rules: {
                title: [{ required: true, message: "标题内容不少于2个字", trigger: "blur" }],
                age: [{ required: true, message: "请输入年龄", trigger: "blur" }],

                zhuan_ye: [
                    {
                        required: true,
                        message: "请选择专业",
                        trigger: "change"
                    }
                ],
                gender: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change"
                    }
                ],
                zhu_su: [{ required: true, message: "主诉内容不少于10个字", trigger: "blur" }],
                xian_bing_shi: [{ required: true, message: "现病史内容不少于10个字", trigger: "blur" }]
            },
            boneInfo: [
                {
                    title: "" /* 标题 */,
                    detail: "" /* 现病史 */,
                    lstPic: "",
                    lstVid: "",
                    type: 0
                },
                {
                    detail: "" /* 诊断 */,
                    pic: [],
                    vid: "",
                    age: "",
                    gender: "",
                    type: 1
                },
                {
                    detail: "" /* 治疗经过 */,
                    pic: [],
                    vid: "",
                    age: "",
                    gender: "",
                    type: 2
                },
                {
                    detail: "" /* 病例思考 */,
                    pic: [],
                    vid: "",
                    age: "",
                    gender: "",
                    type: 3
                },
                { detail: "" /* 关节 */, type: 4 },
                {
                    detail: "" /* 主诉 */,
                    pic: "",
                    vid: "",
                    age: "",
                    gender: "",
                    type: 5
                },
                {
                    detail: "" /* 体格检查/辅助检查 */,
                    pic: [],
                    vid: "",
                    age: "",
                    gender: "",
                    type: 6
                }
            ] /* 提交数据格式 */

            // 图片
            // dialogImageUrl: "",
            // dialogVisible: false,
        };
    },
    computed: {
        userId() {
            return localStorage.getItem("userId") || "";
        },
        token() {
            return localStorage.getItem("token") || "";
        }
    },
    created() {
        this.gId = this.$route.params.gId || "";
    },
    methods: {
        submitFormVideo() {
            if (this.form.age > 120) {
                this.$message({
                    message: "年龄请小于120岁",
                    type: "warning"
                });
                return;
            }

            // 判断是否登录
            if (!(this.userId && this.token)) {
                this.$message.error({
                    forbidClick: true,
                    message: "您未登陆，请登陆账号再发布病例！",
                    overlay: true
                });

                setTimeout(() => {
                    this.$router.push({ name: "Login" });
                }, 2500);
                return;
            }

            this.$refs.form.validate(valid => {
                if (!valid) return false;

                this.$loading({
                    background: "rgba(0, 0, 0, 0.7)",
                    text: "发布中..."
                });

                let {
                    title,
                    gender,
                    age,
                    zhuan_ye,
                    zhu_su,
                    xian_bing_shi,
                    jian_cha,
                    jc_list,
                    zhen_duan,
                    zd_list,
                    zljg,
                    zljg_list,
                    blsk,
                    blsk_list
                } = this.form;

                let params = [];

                let changeArray = arr => {
                    if (!!arr.length) {
                        let tmp = [];
                        arr.map(item => tmp.push(item.imgGid));
                        return tmp.join();
                    }
                    return "";
                };

                this.boneInfo.map(item => {
                    switch (Number(item.type)) {
                        case 0 /* 基本信息 */:
                            params.push({
                                title,
                                detail: xian_bing_shi,
                                lstPic: "",
                                lstVid: "",
                                type: "0",
                                collegeGid: ""
                            });
                            break;
                        case 1 /* 诊断 */:
                            params.push({
                                detail: zhen_duan,
                                pic: changeArray(zd_list),
                                vid: "",
                                age: String(age),
                                gender,
                                type: "1"
                            });
                            break;
                        case 2 /* 治疗经过 */:
                            params.push({
                                detail: zljg,
                                pic: changeArray(zljg_list),
                                vid: "",
                                age: String(age),
                                gender,
                                type: "2"
                            });
                            break;
                        case 3 /* 病例思考 */:
                            params.push({
                                detail: blsk,
                                pic: changeArray(blsk_list),
                                vid: "",
                                age: String(age),
                                gender,
                                type: "3"
                            });
                            break;
                        case 4 /* 专业 */:
                            params.push({ detail: zhuan_ye, type: "4" });
                            break;
                        case 5 /* 主诉 */:
                            params.push({
                                detail: zhu_su,
                                pic: "",
                                vid: "",
                                age: String(age),
                                gender,
                                type: "5"
                            });
                            break;
                        case 6 /* 体格检查/辅助检查 */:
                            params.push({
                                detail: jian_cha,
                                pic: changeArray(jc_list),
                                vid: "",
                                age: String(age),
                                gender,
                                type: "6"
                            });
                            break;
                    }
                });
                console.log(params);

                let data = { resultList: JSON.stringify(params) },
                    headers = {
                        equipmentType: "3", //设备类型： 1：安卓、  2 ：IOS 、 3 ： H5
                        phoneModel: "", //手机型号
                        appVersionNumber: "" //app版本号
                    };

                this.$axios
                    .post("/SD/channel/addMedicalRecord", this.$qs.stringify(data), {
                        headers
                    })
                    .then(res => {
                        this.$loading().close();
                        if (res.data.code == 1) {
                            this.$message({
                                message: "发布成功！",
                                type: "success"
                            });
                            this.$router.push("/");
                        } else {
                            this.$message({
                                message: "发布失败" + res.data.message,
                                type: "warning"
                            });
                        }
                    })
                    .catch(err => {
                        this.$loading().close();
                    });
            });
        },
        // 缩略图 课程卡图片
        uploadImageUrlthumb(file) {},

        // 图片
        handleRemove(file,name) {
            this.form[name].splice(this.form[name+'_File'].indexOf(file), 1);
            this.form[name+'_File'].splice(this.form[name+'_File'].indexOf(file), 1);

            console.log(this.form);
        },
        beforeAvatarUpload(param, index) {
            let fd = new FormData();
            fd.append("imgData", param.file);
            this.$axios.post("/SDpic/common/picUpload", fd).then(res => {
                let data = res.data;
                if (data.code == "1") {
                    this.form[index+'_File'].push(param.file);
                    this.form[index].push(data.imgGid);
                    console.log(this.form);

                    this.$message({
                        message: data.message,
                        type: "success"
                    });
                } else {
                    this.$message.error(data.message);
                }
            });
        }
    }
};
</script>

<style scoped>
#content {
    padding: 40px 0;
}
#BoneAdd {
    background-color: #fafafa;
    margin: 70px 70px 0;
    padding-top: 40px;
    padding-bottom: 40px;
}

.el-radio /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #5680FA !important;
}
.el-radio /deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #5680FA !important;
    border-color: #5680FA !important;
}
.el-radio /deep/ .el-radio__input.is-checked .el-radio__inner:hover{
    border-color: #5680FA !important;
}
.el-upload /deep/ .el-upload--picture-card:hover, .el-upload:focus{
    border-color: #5680FA;
    color: #5680FA;
}

.title {
    height: 50px;
    line-height: 50px;
    margin-left: 0;
    border-bottom: #d9d9d9 1px solid;
    margin-bottom: 15px;
    width: auto;
}

.sui {
    text-align: center;
}

.el-form {
    background-color: #fff;
    border: #d9d9d9 1px solid;
    padding: 0 20px;
}

/* 图片 */
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 120px;
    height: 120px;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
</style>
